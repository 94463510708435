import Home from './Views/Home'
import Profile from './Views/Profile'
import Profiles from './Views/Profiles'
import Article from './Views/Article'
import Expos from './Views/Expos'
import ExpoDetail from './Views/ExpoDetail'
import Evenementielle from './Views/Evenementielle'
import { BrowserRouter, Route, Link, Switch } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import Navbar from './Components/Navbar'

function App() {
    const [partner, setPartner] = useState([]);
    
    useEffect(() => {
        
    }, []);

  return (
    <BrowserRouter>
        <Navbar/>
        
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path={`/evenementielle`} component={Evenementielle}/>
            <Route exact path={`/profiles`} component={Profiles}/>
            <Route exact path={`/profile/:account`} component={Profile}/>
            <Route path={`/profile/:account/article/:id`} component={Article}/>
            <Route exact path={`/expos`} component={Expos}/>
            <Route exact path={`/expo/:id`} component={ExpoDetail}/>
            {/* <Route path={`/about`} component={About}/> */}
            {/* <Route path={`/partners`} component={Partner}/> */}
        </Switch>


        <section>
            <div className="container">
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                    {
                        partner.map((item, id) => {
                            if(item.acc){
                                if(item.url){
                                        <a href={item.url}>
                                            <img src={item.image} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt="" srcset="" />
                                        </a>
                                }else{
                                    return(
                                        <Link to={'/partenaire/'+item.acc}>
                                            <img src={item.image} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt="" srcset="" />
                                        </Link>
                                    )
                                }
                            }else{
                                if(item.url){
                                    <a href={item.url}>
                                        <img src={item.image} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt="" srcset="" />
                                    </a>
                                }else{
                                    return(
                                        <img src={item.image} style={{height: "150px", width: "auto", marginBottom: 30, borderRadius: 20}} alt="" srcset="" />
                                    )
                                }
                            }
                        })
                    }
                </div>
            </div>
        </section>

        <section style={{marginTop: "5rem"}}>
            <div className="" style={{borderTop: "black solid 0px"}}>
                {/* Footer */}
                <footer className="text-center text-lg-start" style={{paddingTop: "2rem", backgroundColor: "#ffc0c0"}}>
                    {/* Section: Links */}
                    <section>
                        <div className="container text-center text-md-start" style={{marginTop: "2.5rem"}}>
                            {/* Grid row */}
                            <div className="row mt-3" style={{marginTop: "3.5rem"}}>
                                {/* Grid column */}
                                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Content */}
                                    <h1 className="fw-bold mb-4" style={{fontSize: "400%"}}>
                                        Ell-Pro
                                    </h1>
                                    <p>
                                        <i>
                                            Ce que femme veut, Dieu veut
                                        </i>
                                        <br />
                                        <br />
                                        C'est une entreprise de communication qui a pour objectif de faire impacter les professionnels par leur visibilité
                                    </p>
                                </div>
                                {/* Grid column */}

                                {/* Grid column */}
                                <div className="col-md-2 col-lg-2 col-xl-2 text-right mx-auto mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Links */}
                                    <h4 className="text-uppercase fw-bold mb-4">
                                        Produits
                                    </h4>
                                    <p>
                                        <Link href="#!" className="text-reset">Blogs</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">Actu'ELL</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">Librairie</Link>
                                    </p>
                                </div>
                                {/* Grid column */}

                                {/* Grid column */}
                                <div className="col-md-3 col-lg-2 col-xl-2 text-right mx-auto mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Links */}
                                    <h4 className="text-uppercase fw-bold mb-4">
                                        Liens
                                    </h4>
                                    <p>
                                        <Link href="#!" className="text-reset">Support</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">A propos</Link>
                                    </p>
                                    <p>
                                        <Link href="#!" className="text-reset">Condition d'utilisation</Link>
                                    </p>
                                </div>
                                {/* Grid column */}

                                {/* Grid column */}
                                <div className="col-md-4 col-lg-3 col-xl-3 text-right mx-auto mb-md-0 mb-4" style={{marginBottom: "4rem"}}>
                                    {/* Links */}
                                    <h4 className="text-uppercase fw-bold mb-4">
                                        Contact
                                    </h4>
                                    <p>
                                        <a>
                                            admin@ell-pro.com
                                        </a>
                                    </p>
                                    <p>
                                        <a>
                                            +261 32 89 405 00
                                        </a>
                                    </p>
                                    <p>
                                        <a>
                                            +261 33 61 558 45
                                        </a>
                                    </p>
                                    <p>
                                        <a>
                                            +261 34 27 382 90
                                        </a>
                                    </p>
                                    <p>
                                        <a>
                                            Andoharanofotsy<br />
                                        </a>
                                    </p>
                                </div>
                                {/* Grid column */}
                            </div>
                            {/* Grid row */}
                        </div>
                    </section>
                    {/* Section: Links  */}

                    {/* Section: Social media */}
                    <section>
                        <div className="container d-flex justify-content-around pb-4" style={{maxWidth: 500}}>
                            <Link href="https://www.linkedin.com/company/73202481/" className="text-reset">
                                Linkedin
                            </Link>
                            <Link href="https://www.instagram.com/ell.pro.sarlu/" className="text-reset">
                                Instagram
                            </Link>
                            <Link href="https://twitter.com/ELLPRO3" className="text-reset">
                                Twitter
                            </Link>
                            <Link href="https://www.facebook.com/Ell-Pro-Madagasikara-101464524820966" className="text-reset">
                                Facebook
                            </Link>
                        </div>
                    </section>

                    <section>
                        <div className="container d-flex justify-content-around pb-4" style={{maxWidth: 500}}>
                            <p>
                                Whatsapp - Viber - Skype
                                <br />
                                +261 32 89 405 00
                            </p>
                        </div>
                    </section>
                    {/* Section: Social media */}


                    {/* Copyright */}
                    <div className="text-center" style={{backgroundColor: "rgba(0, 0, 0, 0.05)", paddingBlock: "3rem"}}>
                        © 2022 Copyright:
                        <Link className="text-reset fw-bold" href="https://ell-pro.com/"> https://ell-pro.com</Link>
                    </div>
                    {/* Copyright */}
                </footer>
                {/* Footer */}
            </div>
        </section>
    </BrowserRouter>  
  );
}

export default App;
