const axios = require('axios').default;

const domain = "https://api.ell-pro.com"
// const domain = "https://ellpro.test"

export function profilesApi (keyword){
    const url = domain+"/api/profiles/"+keyword;

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}


export function profileApi (accId){
  const url = domain+"/api/profile/"+accId;
  
  return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
  })
  .then((response) => response.data)
  .catch((error) => console.error(error))
}

export function articlesApi (accId, keyword){
  const url = domain+"/api/articles/"+accId+"/"+keyword;
  
  return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
  })
  .then((response) => response.data)
  .catch((error) => console.error(error))
}

export function articleApi (id){
    const url = domain+"/api/article/"+id;

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}

export function exposApi (keyword){
    const url = domain+"/api/expos/"+keyword;

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}

export function expoApi (id){
    const url = domain+"/api/expo/"+id;

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}

export function bannerApi (id){
    const url = domain+"/api/banner";

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}

export function aboutApi (){
    const url = domain+"/api/about";

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}