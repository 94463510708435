import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { articleApi } from '../Service/API'
import DOMPurify from 'dompurify'

function Article(props) {
    const { account, id } = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        articleApi(id).then((data)=>{
            if(data){
                setData(data)
            }
        })
    }, []);

    return(
        <div>
            <section style={{marginTop: "3.5rem", marginBottom: "3.5rem"}}>
                <div className="container">
                    <div>
                        <div className="row" style={{height: "300px"}}>
                            <div className="col-md-7 col-lg-8 col-12" style={{backgroundImage: "url('"+data.image+"')", backgroundColor: '#555', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", borderRadius: "2rem", height: "100%", display: "flex", justifyContent: "right"}}>
                                <div style={{position: "absolute", bottom: "2rem", borderRight: "#c89ba2 solid 0rem"}}>
                                    <h1 style={{textAlign: "right", fontSize: "45px"}}>
                                        <span style={{marginInline: "2.5rem", backgroundColor: "#fff9", color: "#000", display: "inline", boxDecorationBreak: "clone", lineHeight: "4rem", padding: "10px", paddingTop: "5px", paddingBottom: "5px", borderRadius: "5px"}}>
                                            {data.title}
                                        </span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-1 col-12"></div>
                            {/* <div className="col-md-4 col-lg-3 col-12" style={{backgroundImage: `url("sqldkfsd")`, backgroundColor: '#555', borderRadius:"2rem", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}></div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section style={{paddingBlock: "5rem"}}>
                <div className="container">
                    <div style={{columns: "2 300px", columnGap: '3em',}} dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(data.body)} } />
                </div>
            </section>
        </div>
    )
}

export default Article;