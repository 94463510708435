import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Fade from 'react-reveal/Fade'
import { profilesApi } from '../Service/API'
import Writing from '../assets/images/writing.jpg'

function Profiles(props) {
    const [data, setData] = useState([])
    const [keyword, setKeyword] = useState("all")

    useEffect(() => {
        window.scrollTo(0,0)
        profilesApi(keyword).then((data)=>{
            if(data){
                setData(data)
            }
        })
    }, [keyword]);

    return(
        <div>
            {/* Redactionn'ell Header */}
            <section>
                <div style={{height: "60vh", backgroundColor: "#ffc0c0"}}>
                    <div className='d-flex align-items-center' style={{position: "absolute", width: "100%", height: "60vh", zIndex: 5}}>
                        <div className='container'>
                            <div className="row">
                                <div className="col-12 col-md-7 col-lg-6" style={{height: "100%"}}>
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <img src="logo Redactionne'll" alt="" style={{width: 120, height: 120, backgroundColor: "#555", overflow: "hidden"}}/>
                                            <div style={{marginLeft: 30, marginRight: 30}}>
                                                <h1 className='title'>Rédactionn'ell</h1>
                                                <h3>Profiles</h3>
                                            </div>
                                        </div>
                                        <br />
                                        <h3>
                                            Le blog pour s'épanouir au travail!
                                            <br />
                                            Petit description de redactionne'll
                                            <br />
                                            Lorem ipsum
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{height: "100%", width: "100%", margin: 0}}>
                        <div className="col-sm-10 col-md-7 col-lg-6" style={{paddingLeft: "5rem", paddingRight: "3rem", paddingTop: "1.5rem", paddingBottom: "1.5rem"}}>
                        </div>
                        <div className='col-sm-2 col-md-5 col-lg-6' style={{backgroundImage: "url("+ Writing +")", backgroundColor: "#fee", backgroundSize: "cover"}}>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            {/* Header */}
            {/* <section className='container pt-5 pb-3'>
                <h1>
                    Top writers
                </h1>
                <br />
                <div
                    style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}
                >
                    <Link to={"/profile/"+"AbioudRABEZANAHARY@51s"}>
                        <div className='d-flex' style={{width: "22rem", overflow:"hidden", marginBottom: "1rem", backgroundColor: "white"}}>
                            <div style={{width: "8rem", height: "8rem", backgroundImage: `url('image')`, backgroundColor: '#555', backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                            </div>
                            <div style={{padding: '1rem', paddingLeft: '1.5rem'}}>
                                    <h4>First LASTNAME</h4>
                                Title
                            </div>
                        </div>
                    </Link>
                    <Link to={"/profile/"+"AbioudRABEZANAHARY@51s"}>
                        <div className='d-flex' style={{width: "22rem", overflow:"hidden", marginBottom: "1rem", backgroundColor: "white"}}>
                            <div style={{width: "8rem", height: "8rem", backgroundImage: `url('image')`, backgroundColor: '#555', backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                            </div>
                            <div style={{padding: '1rem', paddingLeft: '1.5rem'}}>
                                    <h4>First LASTNAME</h4>
                                Title
                            </div>
                        </div>
                    </Link>
                    <Link to={"/profile/"+"AbioudRABEZANAHARY@51s"}>
                        <div className='d-flex' style={{width: "22rem", overflow:"hidden", marginBottom: "1rem", backgroundColor: "white"}}>
                            <div style={{width: "8rem", height: "8rem", backgroundImage: `url('image')`, backgroundColor: '#555', backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                            </div>
                            <div style={{padding: '1rem', paddingLeft: '1.5rem'}}>
                                    <h4>First LASTNAME</h4>
                                Title
                            </div>
                        </div>
                    </Link>
                </div>
            </section> */}
            {/* Profiles */}
            <section className='container pt-5'>
                <div className="row">
                    <h1 className="col-12 col-md-6 col-lg-6">
                        Profiles
                    </h1>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div class="input-group mb-3">
                            <input 
                                type="text"
                                className="form-control"
                                style={{borderRadius: 0}}
                                placeholder="Recherchez un profil ici"
                                onChange={(e) => {
                                    if((e.target.value).replace(" ", "") === ""){
                                        setKeyword("all")
                                    }else{
                                        setKeyword(e.target.value)
                                    }
                                }
                                }
                            />
                            <button className="btn btn-secondary" style={{borderRadius: 0}} type="button" >
                                Recherche
                            </button>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}
                    // onScroll={()=>{
                    //     if(page<maxPage){
                    //         // handleScroll()
                    //     }
                    // }}
                >
                    {
                        (data !== [] && data !== undefined)?(
                            data.map((item, index) => {
                                return(
                                    <Link to={"/profile/"+item.account}>
                                        <div className='d-flex' style={{width: "22rem", overflow:"hidden", marginBottom: "1rem", backgroundColor: "white"}}>
                                            <div style={{width: "8rem", height: "8rem", backgroundImage: "url('"+item.idImage+"')", backgroundColor: '#555', backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                                            </div>
                                            <div style={{padding: '1rem', paddingLeft: '1.5rem'}}>
                                                <h4>{item.firstname + " " + item.lastname}</h4>
                                                <p>
                                                    {item.slug}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        ):(
                            <></>
                        )
                    }
                </div>
            </section>
        </div>
    )
}

export default Profiles;