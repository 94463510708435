import React from 'react'
import { Link } from "react-router-dom"

function Navbar() {

  return (
    <nav className="navbar navbar-expand-lg navbar-light" style={{paddingTop: "1.5rem", paddingBottom: "1.2rem", backgroundColor: "#ffc0c0", paddingInline: 0,}}>
        <div className="container-fluid">
            <div className="collapse navbar-collapse d-flex nowrap" style={{justifyContent: "right"}} id="myNavbar">
                <ul className="navbar-nav" style={{width: "100%", justifyContent: "space-around"}}>
                    <li className="nav-item">
                        <Link className="nav-link" to="/" style={{color: "black"}}>
                            Accueil
                        </Link>
                    </li>
                </ul>
                <ul className="navbar-nav" style={{width: "100%", justifyContent: "space-around"}}>
                    <li className="nav-item dropdown">
                        <Link className="nav-link" to="/evenementielle" style={{color: "black"}}>
                            Evenementi'ELL
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <Link className="navbar-brand" style={{color: "black", fontSize: "35px"}} to="/">
            <span style={{borderTop: "black solid 1px", paddingTop: "1rem",}}>
                ELL
            </span>
            <span style={{borderBottom: "black solid 1px", paddingBottom: "0.5rem",}}>
            PRO
            </span>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="container-fluid">
            <div className="collapse navbar-collapse d-flex nowrap" style={{justifyContent: "left"}} id="myNavbar">
                <ul className="navbar-nav" style={{width: "100%", justifyContent: "space-around"}}>
                    <li class="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Virtu'ELL
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to="/expos" style={{color: "black"}}>
                                Exposition'ELL
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" to="/profiles" style={{color: "black"}}>
                                Rédaction'ELL
                            </Link>
                        </div>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about" style={{color: "black"}}>
                            A propos
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;
