import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Fade from 'react-reveal/Fade'
import Profile from '../assets/images/profile.png'
import Marketing from '../assets/images/marketing1.jpg'
import Event from '../assets/images/event1.jpg'

function Home(props) {

    useEffect(() => {
        window.scrollTo(0,0)
        // var element = document.getElementsByClassName("typing")

        // console.log({element: element[0].innerHTML})

        // const typeWriter = () => {
        //     if(i === 0){
        //         element[0].innerHTML = ""
        //     }
        //     if (i < txt[j].length){
        //         element[0].innerHTML += txt[j].charAt(i)
        //         if (txt[j].charAt(i) === " "){
        //             speed = 200*3/2
        //             i++
        //         }else if (txt[j].charAt(i) === "," || txt[j].charAt(i) === ";"){
        //             speed = 500*3/2
        //             i++
        //         }else if (txt[j].charAt(i) === "."){
        //             if (j === (txt.length - 1)){
        //                 i = 0
        //                 j = 0
        //             }else{
        //                 i = 0
        //                 j++
        //             }
        //             speed = 500*3/2
        //         }else{
        //             speed = 50/2
        //             i++
        //         }
        //     }
        //     setTimeout(typeWriter, speed)
        // }

        // typeWriter()
    }, []);

    // var i = 0
    // var j = 0
    // const txt = [
    //     `La détection des biomolécules ou des produits chimiques est un aspect
    //     important des analyses cliniques.`,
    //     `Particulièrement, dans le cas des maladies. Une
    //     détection précoce augmente considérablement la chance de guérison et de survie.`,
    //     `Les biomarqueurs des maladies confirment leurs établissements dans un individu. Leur
    //     concentration reflète le stade de progression de la maladie.`,
    //     `Il faut remarquer qu’au stade du début de la maladie, la concentration des marqueurs est très basse.`,
    //     `Les méthodes conventionnelles pour la détection des biomarqueurs impliquent des
    //     instruments de laboratoire très sophistiqués qui requièrent un laboratoire dédié, des
    //     spécialistes dans le domaine et demande beaucoup de temps.`
    // ]

    // var speed = 50;

    return(
        <section style={{backgroundColor: "transparent"}}>
            {/* <div style={{position: "absolute", zIndex: -1, backgroundImage: "linear-gradient(0deg, white, transparent 50%, transparent 75%, #ffc0c0), url('https://images.pexels.com/photos/356372/pexels-photo-356372.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", width: "100vw", height: "150vh"}} >
            </div> */}
            {/* <div className='d-flex align-items-center justify-content-center' style={{height: "calc(100vh - 8rem)", width: "100vw"}}>
                <div>
                    <div style={{width: 400, heigh: 200, backgroundColor: "white"}}>
                        <p style={{textAlign: "justify", fontFamily: "calibri"}}>
                            <span className='typing'>

                            </span>
                            <span style={{marginLeft: 3, paddingLeft: 5, paddingRight: 5, paddingTop: 0.5, paddingBottom: 0.5, backgroundColor: "#ffc0c0ff", borderRadius: 2}}>
                                
                            </span>
                        </p>
                    </div>
                </div>
            </div> */}
            <div className='container'>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    paddingTop: "5rem",
                    paddingBottom: "5rem",
                }}>
                    <img 
                        src={Profile}
                        alt="ELL-PRO"
                        style={{
                            height: 250,
                            width: 250,
                            marginBottom: "3rem"
                        }}
                    />
                    <h1 style={{
                        fontFamily: "calibri",
                    }}>
                        PARTENAIRE IDEAL POUR VOTRE BUSINESS
                    </h1>
                    <p style={{
                        textAlign: "center"
                    }}>
                    Soyez différent<br />
                    Mettez en avant vos poins forts<br />
                    ... Et laissez vos résultats s'exprimer
                    </p>
                </div>

                <div
                    style={{
                        display: "flex",
                        backgroundImage: "url("+ Marketing +")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: 7
                    }}
                >
                    <div
                        style={{
                            padding: "2rem",
                            margin: "4rem",
                            background: "white",
                            width: "100%",
                            boxShadow: "0 7px 15px #0005",
                            borderRadius: 7
                        }}
                    >
                        <h1>
                            Virtu'ELL
                        </h1>
                        <hr />
                        <p>
                            <b>
                                Redactionn'ELL
                            </b>
                            <br/>
                            Site vitrine, Barre de recherche, Comparateur de prix, Tout domaine confondu, Toutes les régions
                            <br/>
                            <b>
                                Exposition'ELL
                            </b>
                            <br/>
                            Photo, Nom, Description, Prix, Coordonnées
                        </p>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        backgroundImage: "url("+ Event +")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginTop: "5rem",
                        borderRadius: 7
                    }}
                >
                    <div
                        style={{
                            padding: "2rem",
                            margin: "4rem",
                            background: "white",
                            width: "100%",
                            boxShadow: "0 7px 15px #0005",
                            borderRadius: 7
                        }}
                    >
                        <h1>
                            Evenementi'ELL
                        </h1>
                        <hr />
                        <p>
                            <b>
                                Organisation
                            </b>
                            <br/>
                            Elaboration du plan d'organisation, Attraction des cibles, Présent le jour J
                            <br/>
                            <b>
                                Couverture
                            </b>
                            <br/>
                            Identification de l'auditoire, Médiatisation, Retransmission (direct ou différé)
                        </p>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Home;