import React from 'react'
import { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import Fade from 'react-reveal/Fade'
import { articlesApi, profileApi } from '../Service/API'

function Evenementielle(props) {
    const account = "evenementielle"
    const [pData, setPData] = useState([])
    const [data, setData] = useState([])
    const [keyword, setKeyword] = useState("all")

    useEffect(() => {
        window.scrollTo(0,0)
        profileApi(account).then((data)=>{
            if(data){
                setPData(data)
            }
        })
        articlesApi(account, keyword).then((data)=>{
            if(data){
                setData(data)
            }
        })
    }, [keyword]);

    return(
        <div>
            {/* Redactionn'ell Header */}
            <section>
                <div style={{height: "60vh", backgroundColor: "#ffc0c0"}}>
                    <div className='d-flex align-items-center' style={{position: "absolute", width: "100%", height: "60vh", zIndex: 5}}>
                        <div className='container'>
                            <div className="row">
                                <div className="col-12" style={{height: "100%"}}>
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <img src={pData.idImage} alt={pData.firstname + " " + pData.lastname} style={{width: 150, height: 150, backgroundColor: "#555", overflow: "hidden"}}/>
                                            <div style={{marginLeft: 30, marginRight: 30}}>
                                                <h1 className='title'>Evenementi'ELL</h1>
                                            </div>
                                        </div>
                                        <br />
                                        <h2>
                                            <b>
                                                {pData.slug}
                                            </b>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            {/* Profiles */}
            <section className='container pt-5'>
                <div className="row">
                    <h1 className="col-12 col-md-6 col-lg-6">
                        Mes articles
                    </h1>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div class="input-group mb-3">
                            <input 
                                type="text"
                                className="form-control"
                                style={{borderRadius: 0}}
                                placeholder="Trouvez un article"
                                onChange={(e) => {
                                    if((e.target.value).replace(" ", "") === ""){
                                        setKeyword("all")
                                    }else{
                                        setKeyword(e.target.value)
                                    }
                                }
                                }
                            />
                            <button className="btn btn-secondary" style={{borderRadius: 0}} type="button" >Trouver</button>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    style={{alignItems: "center", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}
                    // onScroll={()=>{
                    //     if(page<maxPage){
                    //         handleScroll()
                    //     }
                    // }}
                >
                    {
                        (data !== [] && data !== undefined)?(
                            data.map((item, index) => {
                                return(
                                    <Link to={"/profile/"+account+"/article/"+item.id}>
                                    <div style={{width: "22rem", overflow:"hidden", marginBottom: "1rem", backgroundColor: "white"}}>
                                        <div style={{width: "22rem", height: "11rem", backgroundImage: "url('"+item.image+"')", backgroundColor: '#555', backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                                        </div>
                                        <div style={{height: "8rem", padding: '1rem', paddingLeft: '1.5rem'}}>
                                                <h4>{item.title}</h4>
                                            {item.summary}
                                        </div>
                                    </div>
                                </Link>
                                )
                            })
                        ):(
                            <></>
                        )
                    }
                </div>
            </section>
        </div>
    )
}

export default Evenementielle;